const namespaced = true

const state = () => ({
  subscriberData: null,
  confirmMessage: ''
})

const getters = {
  getSubscriberData: state => state.subscriberData,
  getConfirmMessage: state => state.confirmMessage
}

const mutations = {
  setSubscriberData (state, data) {
    state.subscriberData = { ...data }
  },
  setConfirmMessage (state, data) {
    state.confirmMessage= data
  },
}

const actions = {
  async checkSubscriberStatus ({ commit },subID) {
    return await this._vm.$axios.get(`/subscribe/${subID}`)
      .then((resp) => {
        if (resp.status === 200) {
          const { data } = resp.data
          commit('setSubscriberData', {accountID: data[0].account_id, status: data[0].active})
        }
      })
  },
  async confirmSubscription ({ commit }, subData) {
    try {
      if (subData.status === 'PENDING'){
        await this._vm.$axios.post(`/subscribe/activate/${subData.subID}`)
        commit('setConfirmMessage', 'Subscribed Successfully')
      } else {
        await this._vm.$axios.post(`/subscribe/remove/${subData.subID}`)
        commit('setConfirmMessage', 'Unsubscribed Successfully')
      }
    }catch (e){
      return e;
    }
  },
  async subscribe (ctx, data) {
    try {
      await this._vm.$axios.post('/subscribe', data)
    } catch (err) {
      console.error(err)
    }
  },

}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
