const namespaced = true

const state = () => ({
    popup: {active: false, message: "" },
    loading: false,
})

const getters = {
    getPopUp: (state) => state.popup,
    getLoading: (state) => state.loading
}

const mutations = {
    setPopup(state, popup) {
        state.popup = popup;
    },
    setLoading(state, loading) {
        state.loading = loading;
    }
}

const actions = {
    showPopup({ commit }, message) {
        commit("setPopup", {
            active: true,
            message: message
        });
    },
    hidePopup({ commit }) {
        commit("setPopup", {
            active: false,
            message: ''
        })
    },

}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
